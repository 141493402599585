import { create } from "zustand"
import { persist, createJSONStorage } from "zustand/middleware"

const initialStore = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    openai_api_key: "",
  },
  workspaces: [],
  rooms: [],
  folders: [],
  accessToken: "",
  refreshToken: "",
  organization: {},
  user_id: "",
  workspace_id: "",
  room_id: "new",
  currentConvo: [],
  question: "",
  answer: "",
  currentRoom: null,
  chat_messages: [],
  gpt_version: "GPT 3.5 Turbo",
  folder_id: "",
  myTeam: [],
  invitations: [],
  isCurrentWorkspaceShared: false,
  sharedRooms: [],
  currentWorkSpace: null,
}

const userStore = (set) => ({
  ...initialStore,
  updateUserStore: (data) => set((state) => ({ ...state, ...data })),
  clearStore: () => set((state) => ({ ...state, ...initialStore })),
  updateRoomId: (room) => set((state) => ({ ...state, room_id: room })),
  updateCurrentRoom: (room_id) =>
    set((state) => {
      const currentRoom = state.rooms?.find((r) => r.room_id == room_id)
      if (currentRoom) {
        return {
          ...state,
          currentRoom,
          room_id,
          chat_messages: currentRoom.chat_messages,
          question: "",
          answer: "",
        }
      }
      return {
        ...state,
        currentRoom,
        room_id,
        chat_messages: [],
        question: "",
        answer: "",
      }
    }),
  updateWorkspace: (workspace_id) =>
    set((state) => {
      const currentWorkSpace = state.workspaces.find(
        (space) => space.workspace_id === workspace_id
      )

      if (currentWorkSpace) {
        return {
          ...state,
          workspace_id,
          rooms: currentWorkSpace.chat_rooms,
          folders: currentWorkSpace.folders,
          room_id: currentWorkSpace.chat_rooms[0]?.room_id || "new",
          isCurrentWorkspaceShared: false,
          currentWorkSpace,
        }
      }

      return {
        ...state,
        workspace_id,
      }
    }),
})

const useUserStore = create(
  persist(userStore, {
    name: "app-user",
    storage: createJSONStorage(() => sessionStorage),
  })
)

export default useUserStore
