import React from "react"
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google"
import { loginWithGmail } from "@/controller/auth"
function GoogleButton() {
  const clientId = process.env.GOOGLE_CLIENT
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <GoogleLogin
          onSuccess={async (response) => {
            loginWithGmail(response.credential).then((path) => {
              window.location.replace(path)
            })
          }}
          onError={() => {
            console.log("Login Failed")
          }}
          shape="square"
          size="medium"
        />
      </div>
    </GoogleOAuthProvider>
  )
}

export default GoogleButton
