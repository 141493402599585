import axios from "axios"
import useUserStore from "@/store/useUserStore"
const baseURL = `${process.env.API_URL}`

const accessTokken = useUserStore.getState().accessToken
const refreshToken = useUserStore.getState().refreshToken
const updateUserStore = useUserStore.getState().updateUserStore

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Authorization: `Bearer ${accessTokken}`,
    accept: "application/json",
  },
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config
    let refreshTokenError, res
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      ;[refreshTokenError, res] = await axios
        .post(`${baseURL}refresh/`, { refresh: refreshToken })
        .then(async (response) => {
          updateUserStore({
            accessTokken: response.data.access,
            refreshToken: response.data.refresh,
          })
          axiosInstance.defaults.headers["Authorization"] =
            "Bearer " + response.data.access
          originalRequest.headers["Authorization"] =
            "Bearer " + response.data.access
          return [null, await axios.request(originalRequest)]
        })
      if (refreshTokenError) {
        return Promise.reject(refreshTokenError)
      }
      return Promise.resolve(res)
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
