import Image from "next/image"
import Link from "next/link"
import { useAppContext } from "@/context/Context"
import React, { useEffect, useState } from "react"

import sal from "sal.js"

import boxedLogo from "../../public/images/logo/boxed-logo.png"

import PageHead from "@/pages/Head"
import {
  LoginWithEmailAndPassword,
  VerifyForgotPassword,
  createAccount,
} from "@/controller/auth"
import { useRouter } from "next/router"
import { toast } from "react-toastify"
import GoogleButton from "../GoogleButton"
const UserAuth = () => {
  const { toggleAuth, setToggleAuth } = useAppContext()
  const [forgetPassword, setforgetPassword] = useState(true)
  const router = useRouter()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    sal()

    const cards = document.querySelectorAll(".bg-flashlight")

    cards.forEach((bgflashlight) => {
      bgflashlight.onmousemove = function (e) {
        let x = e.pageX - bgflashlight.offsetLeft
        let y = e.pageY - bgflashlight.offsetTop

        bgflashlight.style.setProperty("--x", x + "px")
        bgflashlight.style.setProperty("--y", y + "px")
      }
    })
  }, [])

  const login = (e) => {
    e.preventDefault()
    const email = e.target[0].value
    const password = e.target[1].value
    setLoading(true)
    LoginWithEmailAndPassword(email, password)
      .then((path) => {
        window.location.replace(path)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
        e.target.reset()
      })
  }

  const signup = (e) => {
    e.preventDefault()
    const first_name = e.target[0].value
    const last_name = e.target[1].value
    const email = e.target[2].value
    const password = e.target[3].value
    const confirm_password = e.target[4].value
    if (password !== confirm_password) {
      toast.error("Password does not match")
      return
    } else {
      setLoading(true)
      createAccount(first_name, last_name, email, password)
        .then((response) => {
          e.target.reset()
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const forgotpasswordVerfifyOtp = (e) => {
    e.preventDefault()

    const email = e.target[0].value
    const request_type = "send__email"

    const data = {
      request_type: request_type,
      email: email,
    }

    setLoading(true)
    VerifyForgotPassword(data)
      .then((response) => {
        e.target.reset()
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <PageHead title={`${toggleAuth ? "Log In" : "SignUp"}`} />
      <div
        className="signup-area rainbow-section-gapTop-big"
        data-black-overlay="2"
      >
        <div className="sign-up-wrapper rainbow-section-gap">
          <div className="sign-up-box bg-flashlight">
            <div className="signup-box-top top-flashlight light-xl">
              <Image
                src={boxedLogo}
                width={476}
                height={158}
                alt="sign-up logo"
              />
            </div>
            <div className="separator-animated animated-true"></div>
            <div className="signup-box-bottom">
              <div className="signup-box-content">
                <h4 className="title">Welcome Back!</h4>
                <GoogleButton />
                <div className="text-social-area">
                  <hr />
                  <span>Or continue with</span>
                  <hr />
                </div>
                {forgetPassword ? (
                  toggleAuth ? (
                    <form onSubmit={login}>
                      <div className="input-section mail-section">
                        <div className="icon">
                          <i className="feather-mail"></i>
                        </div>
                        <input
                          type="email"
                          placeholder="Enter email address"
                          name="email"
                          id="email"
                          required
                        />
                      </div>
                      <div className="input-section password-section">
                        <div className="icon">
                          <i className="feather-lock"></i>
                        </div>
                        <input
                          type="password"
                          placeholder="Password"
                          name="password"
                          id="password"
                          required
                        />
                      </div>

                      <div className="forget-text">
                        <span
                          className="btn-read-more ps-2"
                          onClick={() => setforgetPassword(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <span>Forgot password</span>
                        </span>
                      </div>

                      <button
                        type="submit"
                        className="btn-default"
                        disabled={loading}
                      >
                        {loading ? "Loading...." : "Sign In"}
                      </button>
                    </form>
                  ) : (
                    <form onSubmit={signup}>
                      <div className="input-section mail-section">
                        <div className="icon">
                          <i className="feather-user"></i>
                        </div>
                        <input
                          type="text"
                          placeholder="Enter Your First Name"
                          name="first_name"
                          id="first_name"
                          required
                        />
                      </div>
                      <div className="input-section mail-section">
                        <div className="icon">
                          <i className="feather-user"></i>
                        </div>
                        <input
                          type="text"
                          placeholder="Enter Last Your Name"
                          name="last_name"
                          id="last_name"
                          required
                        />
                      </div>
                      <div className="input-section mail-section">
                        <div className="icon">
                          <i className="feather-mail"></i>
                        </div>
                        <input
                          type="email"
                          placeholder="Enter email address"
                          name="email"
                          id="email"
                          required
                        />
                      </div>
                      <div className="input-section password-section">
                        <div className="icon">
                          <i className="feather-lock"></i>
                        </div>
                        <input
                          type="password"
                          placeholder="Create Password"
                          name="password"
                          id="password"
                          required
                        />
                      </div>
                      <div className="input-section password-section">
                        <div className="icon">
                          <i className="feather-lock"></i>
                        </div>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          name="confirm_password"
                          id="confirm_password"
                          required
                        />
                      </div>
                      <button
                        type="submit"
                        className="btn-default"
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Sign Up"}
                      </button>
                    </form>
                  )
                ) : (
                  <form onSubmit={forgotpasswordVerfifyOtp}>
                    <div className="input-section mail-section">
                      <div className="icon">
                        <i className="feather-mail"></i>
                      </div>
                      <input
                        type="email"
                        placeholder="Enter email address"
                        name="email"
                        id="email"
                        required
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn-default"
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </button>
                  </form>
                )}
              </div>
              <div className="signup-box-footer">
                <div className="bottom-text">
                  Don&apos;t have an account?
                  <span
                    className="btn-read-more ps-2"
                    onClick={() => {
                      setToggleAuth((toggle) => !toggle),
                        setforgetPassword(true)
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {toggleAuth ? <span>Sign Up</span> : <span>Sign In</span>}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserAuth
