import axios from "axios"
import { toast } from "react-toastify"
import useUserStore from "@/store/useUserStore"
import axiosInstance from "@/utils/axios"
export const LoginWithEmailAndPassword = (email, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.API_URL}login/`, {
        email,
        password,
      })
      .then((response) => {
        const {
          authenticatedUser,
          access_token,
          refresh_token,
          organizations,
          workspaces,
        } = response.data
        const { is_email_verified, user_id } = authenticatedUser
        if (!is_email_verified) {
          toast.error("Please verify your email")
        } else {
          useUserStore.setState((state) => ({
            ...state,
            user_id: user_id,
            user: authenticatedUser,
            accessToken: access_token,
            refreshToken: refresh_token,
          }))
          if (organizations === 0) {
            return resolve("/org-info")
          }
          if (workspaces === 0) {
            return resolve("/workspace-info")
          }
          return resolve("/text-generator")
        }
      })
      .catch((error) => {
        if (error.response.status === 500) {
          toast.error("Error Connecting to Server")
          return reject(error?.response?.data?.message)
        }
        toast.error(error?.response?.data?.message)
        return reject(error?.response?.data?.message)
      })
  })
}

export const createAccount = (first_name, last_name, email, password) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.API_URL}signup/`, {
        first_name,
        last_name,
        email,
        password,
      })
      .then((response) => {
        const { authenticatedUser, message } = response.data
        const { user_id } = authenticatedUser
        toast.success(message)
        useUserStore.setState((state) => ({
          ...state,
          user_id: user_id,
          user: authenticatedUser,
        }))
        return resolve("User Created")
      })
      .catch((err) => {
        console.log(err.response)
        toast.error("Error connecting to server")
        reject(err?.response?.data?.message)
      })
  })
}

export const VerifyForgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.API_URL}reset-password/`, data)
      .then((response) => {
        console.log(response.data, "verfiy account")
        const { message } = response.data

        console.log(message, "ss")
        toast.success(message)

        return resolve(message)
      })
      .catch((err) => {
        console.log(err.response)
        toast.error("Error connecting to server")
        reject(err?.response?.data?.message)
      })
  })
}
export const VerifyResetPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.API_URL}reset-password/`, data)
      .then((response) => {
        console.log(response.data, "verfiy account")
        const { message } = response.data
        console.log(message, "message")
        toast.success(message)

        return resolve("/AuthPage")
      })
      .catch((err) => {
        console.log(err.response, "errprrrrr")
        console.log(err?.response?.data?.error?.message, "errrrrrrrrr")
        toast.error(err?.response?.data?.error?.message)
        reject(err?.response?.data?.error?.message)
      })
  })
}

export const verifyAccount = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.API_URL}verify/`, { token })
      .then((response) => {
        const { authenticatedUser, message, access_token, refresh_token } =
          response.data
        const { user_id } = authenticatedUser
        toast.success(message)
        useUserStore.setState((state) => ({
          ...state,
          user_id: user_id,
          user: authenticatedUser,
          accessToken: access_token,
          refreshToken: refresh_token,
        }))
        return resolve("/org-info")
      })
      .catch((err) => {
        console.log(err.response)
        toast.error("Invalid request")
        reject(err?.response?.data?.message)
      })
  })
}

export const updateProfileInfo = () => {
  return new Promise((resolve, reject) => {
    const user_id = useUserStore.getState().user_id
    axiosInstance
      .patch(`users/${user_id}/`, {
        first_name: useUserStore.getState().user.first_name,
        last_name: useUserStore.getState().user.last_name,
        email: useUserStore.getState().user.email,
        openai_api_key: useUserStore.getState().user.openai_api_key,
      })
      .then(() => {
        toast.success("Profile Updated Successfully")
        return resolve("Profile Updated Successfully")
      })
      .catch((err) => {
        toast.error("Error updating profile")
        reject(err?.response?.data?.message)
      })
  })
}

export const loginWithGmail = (token) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.API_URL}oauth/`, { auth_token: token })
      .then((response) => {
        const {
          authenticatedUser,
          access_token,
          refresh_token,
          organizations,
          workspaces,
        } = response.data
        const { user_id } = authenticatedUser

        useUserStore.setState((state) => ({
          ...state,
          user_id: user_id,
          user: authenticatedUser,
          accessToken: access_token,
          refreshToken: refresh_token,
        }))
        if (organizations === 0) {
          return resolve("/org-info")
        }
        if (workspaces === 0) {
          return resolve("/workspace-info")
        }
        return resolve("/text-generator")
      })
      .catch((err) => {
        console.log(err.response)
        toast.error(err?.response?.data?.message)
        reject(err?.response?.data?.message)
      })
  })
}

export const updatePassword = (newPassword, oldPassword) => {
  return new Promise((resolve, reject) => {
    const email = useUserStore.getState().user.email

    axiosInstance
      .post("update-password/", {
        email,
        current_password: oldPassword,
        new_password: newPassword,
      })
      .then((response) => {
        const { message } = response.data
        toast.success(message)
        return resolve(message)
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.error.message)
        } else {
          toast.error("Error while processing request")
        }
        return reject(error)
      })
  })
}
